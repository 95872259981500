import React from 'react';
import { graphql, Link } from 'gatsby';
import styled, { createGlobalStyle } from 'styled-components';
import Layout from 'components/Layout';
import Header from 'components/Header';
import Seo from 'components/SEO';
import headerPro from 'images/headerPro.svg';
import { PrismicRichText } from '@prismicio/react';
import { Container, Button, Buttons } from 'ui';
import YouTube from 'react-youtube';
import { MdChevronLeft, MdInsertDriveFile } from 'react-icons/md';
import qs from 'query-string';

const Content = styled.article``;

const YouTubeWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: ${({ theme }) => theme.spacing(2)};
`;

const Attachments = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-left: ${({ theme }) => theme.spacing(-2)};
  margin-top: ${({ theme }) => theme.spacing(-1)};
`;

const Attachment = styled.a`
  margin-top: ${({ theme }) => theme.spacing()};
  margin-left: ${({ theme }) => theme.spacing(2)};
  display: flex;
  align-items: center;
  border-radius: 100px;
  text-decoration: none;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.12);
  color: ${({ theme }) => theme.primary};
  border: 1px solid ${({ theme }) => theme.primary};
  padding: ${({ theme }) => theme.spacing(0.5)}
    ${({ theme }) => theme.spacing(1)};

  svg {
    margin-right: ${({ theme }) => theme.spacing(0.5)};
    font-size: 1.25rem;
  }

  &:after {
    content: '' !important;
  }
`;

const ArticleStyles = createGlobalStyle`
  h2 {
    font-size: 2rem;
    font-weight: bold;
  }
  h3 {
    font-size: 1.625rem;
    text-transform: uppercase;
    font-weight: 400;
    color: ${({ theme }) => theme.primary};
    padding-top: ${({ theme }) => theme.spacing(1.75)};
  }

  a {
    color: ${({ theme }) => theme.linkColor};
    text-decoration: none;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    strong {
      font-weight: inherit;
    }
  }
`;

const ProArticle = ({ data }) => {
  const { body, description, main_video_youtube, title } =
    data?.prismicProArticle?.data;

  const getIdFromLink = url => {
    const urlParams = qs.parse(url.split('?')[1]);
    return urlParams.v;
  };

  return (
    <Layout>
      <Seo title={title?.text} description={description && description?.text} />
      <ArticleStyles />
      <Header
        title="Accès professionnels de santé"
        icon={headerPro}
        variant="withLogo"
        app="pro"
      />
      <Container>
        <Buttons style={{ justifyContent: 'flex-start' }} hasMarginBottom>
          <Button variant="primary" as={Link} to="/pro">
            <MdChevronLeft />
            Retour
          </Button>
        </Buttons>
        <Content>
          <PrismicRichText field={title?.richText} />
          <PrismicRichText field={description?.richText} />
          {Boolean(main_video_youtube) && (
            <YouTubeWrapper>
              <YouTube
                videoId={getIdFromLink(main_video_youtube)}
                opts={{
                  width: 640,
                  height: 360,
                }}
              />
            </YouTubeWrapper>
          )}

          {(body || []).map(({ items, primary }) => {
            const attachments = (items ?? []).filter(({ section_document }) =>
              Boolean(section_document?.url),
            );

            return (
              <div>
                <PrismicRichText field={primary?.section_title?.richText} />
                <PrismicRichText field={primary?.section_content?.richText} />
                {attachments.length > 0 && (
                  <Attachments>
                    {attachments.map(({ section_document }) => (
                      <Attachment
                        href={section_document?.url}
                        target="_blank"
                        rel="noopener noreferer"
                        download
                      >
                        <MdInsertDriveFile />

                        {section_document?.raw?.name}
                      </Attachment>
                    ))}
                  </Attachments>
                )}
                {Boolean(primary.section_video_youtube) && (
                  <YouTubeWrapper>
                    <YouTube
                      videoId={getIdFromLink(primary?.section_video_youtube)}
                      opts={{
                        width: 640,
                        height: 360,
                      }}
                    />
                  </YouTubeWrapper>
                )}
              </div>
            );
          })}
        </Content>
      </Container>
    </Layout>
  );
};

export const pageQuery = graphql`
  query articleQuery($slug: String!) {
    prismicProArticle(lang: { eq: "fr-fr" }, uid: { eq: $slug }) {
      uid
      data {
        title {
          text
          richText
        }
        description {
          text
          richText
        }
        main_video_youtube

        body {
          ... on PrismicProArticleDataBodySection {
            id
            items {
              section_document {
                url
                uid
                raw
              }
            }
            primary {
              section_content {
                richText
              }
              section_title {
                richText
              }
              section_video_youtube
            }
            slice_type
            slice_label
          }
        }
      }
    }
  }
`;

export default ProArticle;
